#sidebar {
    z-index: 12 !important;
}

#sidebarOverlay {
    z-index: 11 !important;
}

.written-logo {
    display: flex;
    align-items: center;
}

.written-logo.margin-left {
    margin-left: 25px;
}

.written-logo img {
    height: 54px;
    max-width: 150px;
}

body {
    background-color: white;
    font-family: 'Nunito', sans-serif;
}

.clickable:hover {
    cursor: pointer;
}

.hidden {
    visibility: hidden;
}

.gone {
    display: none;
}

.text-link {
    color: #3300FF;
}

.text-link:hover {
    color: #8052FF;
    cursor: pointer;
}

.black-antd-color {
    background-color: #21009c;
}

.notification-item {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    background-color: white;
    padding: 10px 24px;
    padding-right: 4px;
}

.notification-item .notification-time {
    font-size: 12px;
}

.notification-item .notification-text {
    display: flex;
    align-items: center;
    hyphens: auto;
    background-color: white;
}

.notification-item:hover .notification-text, .notification-item:hover .notification-time {
    cursor: pointer;
    color: #3300FF;
}

.clickable {
    cursor: pointer;
}

/*.custom-antd-form .ant-form-item {*/
/*    margin-bottom: 9px;*/
/*}*/

/*.custom-antd-form .ant-form-explain {*/
/*    font-size: smaller;*/
/*    min-height: 15px;*/
/*    margin-bottom: -15px;*/
/*}*/

/*.custom-antd-form .ant-input {*/
/*    border: none;*/
/*    border-bottom: 1px solid #d9d9d9;*/
/*    border-radius: unset;*/
/*}*/

/*.custom-antd-form .ant-input-affix-wrapper .ant-input-prefix {*/
/*    left: 0px;*/
/*    color: #d9d9d9;*/
/*    !*padding-left: 23px;*!*/
/*}*/

/*.custom-antd-form .ant-input-affix-wrapper .ant-input:not(:first-child) {*/
/*    padding-left: 23px;*/
/*}*/

/*.ant-checkbox-checked .ant-checkbox-inner {*/
/*    background-color: #3300FF;*/
/*    border-color: #3300FF;*/
/*}*/

/*.ant-checkbox-disabled .ant-checkbox-inner {*/
/*    color: red;*/
/*}*/

/*.ant-btn-primary {*/
/*    background-color: #3300FF;*/
/*    border-color: #3300FF;*/
/*}*/

/*.ant-btn-primary:hover {*/
/*    background-color: #ad99ff;*/
/*    border-color: #ad99ff;*/
/*}*/

/*.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-layout-header {*/
/*    color: fff;*/
/*    background-color: #21009c;*/
/*}*/

/*.ant-menu-dark .ant-menu-sub .ant-menu-item {*/
/*    background-color: #19005c;*/
/*}*/

/*.ant-menu.ant-menu-dark .ant-menu-item-selected {*/
/*    background-color: #5d35ff;*/
/*}*/

/*.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-item:not(:last-child) {*/
/*    margin-top: 0;*/
/*    margin-bottom: 0;*/
/*}*/

.antd-customize *[role="menuitem"] {
    height: 54px;
    line-height: 54px;
}

button.antd-customize.ant-btn.ant-btn-link {
    font-weight: bold;
}

button.antd-customize.ant-btn.ant-btn-link:disabled {
    color: grey;
}

.main-container {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    overflow-x: hidden;
}

.max-width-container {
    max-width: 800px;
    width: 100%;
    padding: 10px;
}

.custom-scrollbar ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.custom-scrollbar ::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 6px;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
    background: #f0f0f0;
    border-radius: 8px;
}