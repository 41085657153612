.mainContainer {
    max-width: 950px;
    margin: auto;
}

.cover {
    position: relative;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.coverTextContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: white;
    backdrop-filter: blur(5px);
    padding: 20px;
    width: max-content;
    max-width: 100%;
    max-height: 100%;
    text-align: end;
    overflow: hidden;
    text-shadow: 0px 0px 45px black;
}

.coverTextDestination {
    font-size: 20px;
}

.coverTextDeparture {
    font-size: 15px;
}

.dateContainer {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin: 5px 0;
    padding: 5px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
}

.date {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    text-align: center;
}

.date:hover {
    background: rgba(0, 0, 255, 0.36);
}

.date:first-child, .date:last-child {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
}

.active {
    background: blue;
    color: white;
}

.tripCard {
    border: 1px solid lightgrey;
    border-radius: 8px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: #f8f8f8;
}

.cityContainer {
    width: 200px;
    display: flex;
    flex-direction: column;
}

.departure {
    grid-area: departure;
}

.arrival {
    grid-area: arrival;
}

.city {
    font-size: 20px;
    margin-bottom: 0;
}

.arrow {
    grid-area: arrow;
    color: #c3c3c3;
    font-size: 20px;
}

.tripTimeContainer {
    display: flex;
    align-items: baseline;
    gap: 5px;
}

.tripBoardingText {
    line-height: 30px;
}

.tripTime {
    font-size: 20px;
}

.statusContainer {
    grid-area: status;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-self: center;
    width: 140px;
}

.remainingSeats {
    color: red;
}

.tripStatus {
    font-weight: bold;
}

.scheduled {
    color: orange;
}

.confirmed {
    color: green;
}

.cancelled {
    color: red;
}

.priceContainer {
    grid-area: price;
    font-size: 20px;
    white-space: nowrap;
}

.detailsButton {
    grid-area: detailsButton;
}

@media (max-width: 560px) {
    .dateContainer > *:nth-child(5) {
        display: none;
    }

    .tripCard {
        display: grid;
        align-items: center;
        grid-template-areas:
                'departure arrow arrival'
                'departure arrow arrival'
                'departure arrow arrival'
                'status . price'
                'status . detailsButton';
        gap: 10px;
        padding: 10px;
    }

    .cityContainer {
        width: auto;
        align-self: start;
    }

    .departure {
        text-align: right;
        justify-self: end;
        align-items: end;
    }

    .arrow {
        justify-self: center;
    }

    .statusContainer {
        width: auto;
    }

    .priceContainer {
        justify-self: end;
        align-self: end;
    }

    .detailsButton {
        justify-self: end;
        align-self: start;
    }
}

@media (min-width: 561px) and (max-width: 768px) {
    .tripCard {
        height: 220px;
        display: grid;
        align-items: center;
        grid-template-areas:
                'arrow departure status price'
                'arrow departure status price'
                'arrow arrival status detailsButton'
                'arrow arrival status detailsButton';
        gap: 10px;
        padding: 10px;
    }

    .tripCard > * {
        display: flex;
        justify-content: center;
    }

    .arrow {
        transform: rotate(90deg);
    }

    .priceContainer {
        align-self: end;
    }

    .detailsButton {
        align-self: start;
    }
}

@media (min-width: 769px) {
    .tripCard {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        height: 120px;
        border: 1px solid lightgrey;
        border-radius: 8px;
        padding: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        background: #f8f8f8;
    }

    .departure {
        text-align: right;
    }

    .tripTimeContainer {
        justify-content: end;
    }
}

.tripsLoadingSkeletonTitleContainer {
    padding: 5px 10px;
    width: 100%;
    max-width: 400px;
}

.tripsLoadingSkeletonParagraphContainer {
    padding: 10px;
}

.dateLoadingSkeletonContainer {
    width: 100px;
    display: flex;
    align-items: center;
}

.dateLoadingSkeletonContainer span {
    flex-grow: 1;
}